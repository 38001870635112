import formInputFieldPropsMixin from './formInputFieldProps.js';

export default {
  mixins: [formInputFieldPropsMixin],
  props: {
    value: {
      required: true,
    },
    emptyOnSelect: {
      type: Boolean,
      default: false,
    },
  },
};
