<template>
  <div @click.prevent>
    <vueSelect
      :id="inputId"
      :placeholder="placeholder"
      :value="value"
      :filterable="false"
      :options="towns"
      @search="onSearchTown"
      @change="resetSearchTown"
      @input="setTown"
      :disabled="disabled"
      :readonly="disabled"
    >
      <template #no-options>
        <template v-if="autocompleteActive">
          Nessuna città trovata
        </template>
        <span v-else>
          Digitare due lettere per cercare una città
        </span>
      </template>
      <template #option="option">
        <div class="d-center">
          {{ option.name }} ({{ option.province }})
        </div>
      </template>
      <template #selected-option="option">
        {{ option.name }} ({{ option.province }})
      </template>
    </vueSelect>
  </div>
</template>

<script>

import debounce from 'tiny-debounce';
import axios from 'axios';
import formSelectTownMixin from './mixins/formSelectTown.js';

import vueSelect from 'vue-select';

const CancelToken = axios.CancelToken;

export default {
  mixins: [formSelectTownMixin],
  components: {
    vueSelect,
  },
  data: () => ({
    autocompleteActive: false,
    cancelTokenSource: null,
    towns: [],
  }),
  methods: {
    resetSearchTown () {
      this.towns = [];
      this.autocompleteActive = false;
    },
    onSearchTown (search, loading) {
      if (search && search.length > 1) {
        this.autocompleteActive = true;
        loading(true);
      }
      this.searchTown(loading, search, this);
    },
    searchTown: debounce((loading, search, vm) => {
      if (vm.cancelTokenSource) {
        vm.cancelTokenSource.cancel();
      }
      vm.cancelTokenSource = null;

      if (!search || search.length < 2) {
        vm.resetSearchTown();
        loading(false);

        return;
      }

      vm.cancelTokenSource = CancelToken.source();

      vm.$api
        .autocompleteTowns(search, vm.cancelTokenSource.token)
        .then(res => {
          vm.towns = res.data.towns || [];
        })
        .finally(() => loading(false))
      ;
    }, 350),
    setTown (val) {
      this.$emit('input', val);

      if (this.emptyOnSelect) {
        this.towns = [];
        this.autocompleteActive = false;
      }
    },
  },
};

</script>
