<template>
  <FormInputSelectWidget :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg" :options="categoryOptions" :value="value" @input="$emit('input', $event)" />
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget.js';

import FormInputSelectWidget from '@/libs/Form/components/Inputs/Widgets/FormInputSelectWidget.vue';

export default {
  mixins: [formInputWidgetMixin],
  components: {
    FormInputSelectWidget,
  },
  props: {
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({ categories: null }),
  computed: {
    categoryOptions () {
      let categories = [];

      if (this.categories === null) {
        return categories;
      }

      if (this.showAll) {
        categories.push({ value: null, label: 'Tutti' });
      }

      categories = [...categories, ...this.categories.map(c => {
        c.value = c.id;
        c.label = c.name;

        return c;
      })];

      return categories;
    },
  },
  mounted () {
    this.$api.fetchCategories()
      .then(res => {
        this.categories = res.data.categories;
      })
      .catch(this.$log.error)
    ;
  },
};

</script>
