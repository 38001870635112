<template>
  <FormInputSelectWidget :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg" :options="categoryOptions" :value="value" @input="$emit('input', $event)" />
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget.js';

import FormInputSelectWidget from '@/libs/Form/components/Inputs/Widgets/FormInputSelectWidget.vue';

export default {
  mixins: [formInputWidgetMixin],
  components: {
    FormInputSelectWidget,
  },
  data: () => ({ languages: [{ code: 'it', label: 'Italiano' }, { code: 'en', label: 'English' }] }),
  computed: {
    categoryOptions () {
      let languages = [];

      languages = [...languages, ...this.languages.map(c => {
        c.value = c.code;

        return c;
      })];

      return languages;
    },
  },
};

</script>
