<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>
    <FeedbackAlert v-else-if="feedback !== null">{{ feedback }}</FeedbackAlert>

    <div class="row">
      <div class="col-12 col-sm-6">
        <FormLabel label-is-fake not-required>Logo dello shop</FormLabel>
        <div class="row no-gutters">
          <div class="col-auto">
            <ImagePreview instance="logo" v-for="file in uploaderFiles.logo" :image="file" :key="file.code" @remove="removeFile" @replace="replaceFile" />
          </div>
          <div class="col-auto">
            <Uploader ref="uploader-logo" instance="logo" :max="maxFiles.logo" v-model="uploaderFiles.logo" :uploadApiEndpoint="startUpload" :allowedFiles="['image/*']">
              Scegli logo dello shop
            </Uploader>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <FormLabel label-is-fake not-required>Copertina dello shop</FormLabel>
        <div class="row no-gutters">
          <div class="col-auto">
            <ImagePreview instance="cover" v-for="file in uploaderFiles.cover" :image="file" :key="file.code" @remove="removeFile" @replace="replaceFile" />
          </div>
          <div class="col-auto">
            <Uploader ref="uploader-cover" instance="cover" :max="maxFiles.cover" v-model="uploaderFiles.cover" :uploadApiEndpoint="startUpload" :allowedFiles="['image/*']">
              Scegli immagine rappresentativa dello shop
            </Uploader>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <FormInputTextWidget
        class="col-lg-6"
        label="Nome dello shop"
        v-model="settings.name"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
      />

      <SelectLanguageWidget
        class="col-lg-6"
        label="Lingua"
        v-model="settings.language"
        :errorMsg="errorHash.language"
        @input="removeFieldError('language')"
      />

      <SelectCategoryWidget
        class="col-lg-6"
        label="Categoria"
        v-model="settings.category_id"
        :errorMsg="errorHash.category_id"
        @input="removeFieldError('category_id')"
      />

      <FormInputTextWidget
        v-if="edit"
        class="col-lg-6"
        label="Email"
        v-model="settings.email"
        :errorMsg="errorHash.email"
        @input="removeFieldError('email')"
      />

      <FormInputTextWidget
        class="col-lg-6"
        label="Numero di telefono"
        not-required
        v-model="settings.phone"
        :errorMsg="errorHash.phone"
        @input="removeFieldError('phone')"
      />

      <FormInputTextWidget
        class="col-lg-6"
        label="Sito web"
        not-required
        v-model="settings.website"
        :errorMsg="errorHash.website"
        @input="removeFieldError('website')"
      />

      <FormInputTextareaWidget
        class="col-lg-12"
        label="Descrizione"
        v-model="settings.description"
        :errorMsg="errorHash.description"
        @input="removeFieldError('description')"
      />

      <FormInputSelectTownWidget
        class="col-lg-6"
        label="Città"
        v-model="settings.town"
        :errorMsg="errorHash.town"
        @input="removeFieldError('town')"
      />

      <FormInputTextWidget
        class="col-lg-6"
        label="Via"
        v-model="settings.address_name"
        :errorMsg="errorHash.address_name"
        @input="removeFieldError('address_name')"
      />

      <FormInputTextWidget
        class="col-lg-6"
        label="Civico"
        not-required
        v-model="settings.address_number"
        :errorMsg="errorHash.address_number"
        @input="removeFieldError('address_number')"
      />

      <FormInputTextWidget
        class="col-lg-6"
        label="Cap"
        v-model="settings.zip_code"
        :errorMsg="errorHash.zip_code"
        @input="removeFieldError('zip_code')"
      />
    </div>

    <div v-if="!onOnboarding" class="row">
      <FormInputTextareaWidget
        class="col-lg-12"
        label="Note in fondo alla pagina"
        v-model="settings.footerNotes"
        :errorMsg="errorHash.footerNotes"
        @input="removeFieldError('footerNotes')"
        not-required
      />
    </div>

    <div class="row">
      <div class="col-auto mt-3">
        <button class="btn btn-primary px-5">
          <template v-if="edit">Salva</template>
          <template v-else>Completa i dati dello shop</template>
        </button>
      </div>
      <div class="col-auto mt-3" v-if="onOnboarding">
        <router-link :to="{ name: 'logout' }" class="btn btn-outline-secondary px-5">
          Esci e riprendi dopo
        </router-link>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import { emailIsValid } from '@/helpers/validation.js';
import validateMixin from '@/libs/Form/mixins/validate.js';
import uploadFilesMixin from '@/libs/Uploader/mixins/uploadFiles.js';
import rolesMixin from '@/mixins/roles.js';

import FormContainer from '@/views/components/Form/FormContainer.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FormInputTextareaWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextareaWidget.vue';
import FormInputSelectTownWidget from '@/libs/Form/components/Inputs/Widgets/FormInputSelectTownWidget.vue';
import SelectCategoryWidget from '@/views/components/Form/Widgets/SelectCategoryWidget.vue';
import SelectLanguageWidget from '@/views/components/Form/Widgets/SelectLanguageWidget.vue';
import FormLabel from '@/libs/Form/components/FormLabel.vue';
import Uploader from '@/libs/Uploader/components/Uploader.vue';
import ImagePreview from '@/libs/Uploader/components/ImagePreview.vue';

export default {
  mixins: [uploadFilesMixin, validateMixin, rolesMixin],
  components: {
    FormContainer,
    FormInputTextWidget,
    FormInputTextareaWidget,
    FormInputSelectTownWidget,
    SelectLanguageWidget,
    SelectCategoryWidget,
    FormLabel,
    Uploader,
    ImagePreview,
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  props: {
    settings: {
      type: Object,
      default: () => ({
        name: null,
        language: 'it',
        category_id: null,
        description: null,
        email: null,
        phone: null,
        website: null,
        footerNotes: null,
        address_name: null,
        address_number: null,
        zip_code: null,
        town: null,
        logo: null,
        cover: null,
      }),
    },
    feedback: {
      type: Boolean,
      default: null,
    },
    onOnboarding: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      uploader: [
        { name: 'logo', fixedMaxFiles: 1 },
        { name: 'cover', fixedMaxFiles: 1 },
      ],
      uploaderFiles: {
        logo: this.settings.logo ? [{ ...this.settings.logo }] : [],
        cover: this.settings.cover ? [{ ...this.settings.cover }] : [],
      },
    };
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.settings.name) {
        this.addError('Inserisci il nome dello shop', 'name');
      }

      if (!this.settings.category_id) {
        this.addError('Inserisci la categoria', 'category_id');
      }

      if (this.edit && (!this.settings.email || !emailIsValid(this.settings.email))) {
        this.addError('Inserisci l\'email', 'email');
      }

      if (!this.settings.description) {
        this.addError('Inserisci la descrizione', 'description');
      }

      if (!this.settings.address_name) {
        this.addError('Inserisci il nome della via', 'address_name');
      }

      if (!this.settings.zip_code) {
        this.addError('Inserisci il cap', 'zip_code');
      }

      if (!this.settings.town) {
        this.addError('Inserisci la città', 'town');
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      if (!this.uploadsFinishedAndReadyToSubmitInInstance('logo')) {
        this.addError('logo', 'Caricamento immagine in corso');

        return;
      }

      if (!this.uploadsFinishedAndReadyToSubmitInInstance('cover')) {
        this.addError('cover', 'Caricamento immagine in corso');

        return;
      }

      const settingsToReturn = { ...this.settings };
      settingsToReturn.cover_image = this.uploaderFilesToSubmit.cover.length > 0 ? { ...this.uploaderFilesToSubmit.cover[0] } : null;
      settingsToReturn.logo_image = this.uploaderFilesToSubmit.logo.length > 0 ? { ...this.uploaderFilesToSubmit.logo[0] } : null;

      this.$emit('submitForm', settingsToReturn);
    },
  },
  watch: {
    'settings.logo': function (newValue) {
      this.uploaderFiles.logo = newValue ? [{ ...newValue }] : [];
    },
    'settings.cover': function (newValue) {
      this.uploaderFiles.cover = newValue ? [{ ...newValue }] : [];
    },
  },
};

</script>
